
import {
  defineComponent,
  Ref,
  ref,
  onMounted,
} from "vue";
import ModalLojaValidacaoJetoil from "./components/ModalLojaValidacaoJetoil.vue";
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import { useRouter, useRoute, LocationQueryValue } from "vue-router";
import { Modal } from "bootstrap";
import Pagination from "@/components/Pagination.vue";
import Api from "@/services/Api";
import useEmitter from "@/composables/Emmiter";

interface Input {
  cnpj: string,
  razao_social: string,
  estado: string,
  consultor: string
}


export default defineComponent({
  name: "LojasValidacaoJetoil",

  components: {
    ModalLojaValidacaoJetoil,
    Title,
    Button,
    Pagination,
  },

  setup() {
    const router = useRouter();

    const route = useRoute();
    const emitter = useEmitter();

    const page: Ref<number> = ref(1)
    const perPage: Ref<number> = ref(10);

    const limite: Ref<number> = ref(20);

    const lojasArray: Ref<any[]> = ref([]);
    const totalLojas: Ref<number> = ref(0);


    const lojaModal: Ref<any> = ref({});
    const modalUsuario: Ref<any> = ref();

    const loadLojas: Ref<boolean> = ref(false);

    //refs recebidos do FilterUser
    const input: Ref<Input> = ref({
      cnpj: "",
      razao_social: "",
      estado: "",
      consultor: ""
    });

    const idLoja: Ref<LocationQueryValue[] | string | null> = ref(null)
    const lojaFixada: Ref<any> = ref(null)
    const fastFind: Ref<string> = ref('');


    function openModal(loja?) {
      lojaModal.value = loja || {}
      modalUsuario.value.show()
    }

    async function fechaModal() {
      modalUsuario.value.hide()
    }


    async function getLojas() {
      const { cnpj, razao_social, estado, consultor } = input.value
      loadLojas.value = true
      try {
        const { data, error, message } = await Api.get("buscaLojaValidacaoJetoilPaginado", {
          page: page.value,
          perPage: perPage.value,
          cnpj,
          razao_social,
          estado,
          consultor,
          search: fastFind.value
        })
        lojasArray.value = data.lojas
        totalLojas.value = data.total

      } catch (error) {
        console.log("error", error)
      } finally {
        loadLojas.value = false
      }
    }


    emitter.on("Filter-LojasValidacaoJetoil", (valor: any) => {
      if (valor) input.value = valor;
      page.value = 1
      getLojas()
    })

    emitter.on("enviaLojaValidacaoIpirangaLista", (valor: any) => {

      const index = lojasArray.value.findIndex(({ id }) => id === valor.loja.id)

      if (index !== -1) lojasArray.value[index] = { ...valor.loja }
      else lojasArray.value.unshift({ ...valor.loja })

    })

    emitter.on("removeLojaValidacaoIpirangaLista", (valor: any) => {

      const index = lojasArray.value.findIndex(({ id }) => id === valor.loja.id)

      if (index !== -1) lojasArray.value.splice(index, 1)

    })

    function buscar() {
      //função para buscar usuarios no filtro rapido utilizando keyup
      if (fastFind.value.length > 2 || !fastFind.value) {
        page.value = 1
        getLojas()
      }
    }

    function handlePageChange(pageParam) {
      page.value = pageParam;
      getLojas();
    }

    onMounted(async () => {
      modalUsuario.value = new Modal(document.querySelector(`#ModalLojasValidacaoJetoil`));
      getLojas()

    })

    return {
      lojasArray,
      limite,
      history,
      openModal,
      fechaModal,
      router,
      loadLojas,
      getLojas,
      lojaModal,
      idLoja,
      lojaFixada,
      fastFind,
      buscar,
      route,
      handlePageChange,
      totalLojas
    };
  },
});
