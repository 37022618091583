
import Api from "@/services/Api";
import { defineComponent, Ref, ref, watch } from "vue";
import type { PropType } from "vue";
import useAlert from '@/composables/Alert'
import useEmitter from "@/composables/Emmiter";
import Button from "@/components/Button.vue";


export default defineComponent({
  name: "ModalLojasValidacaoIpiranga",
  components: {
    Button,
  },
  props: {
    data: Object as PropType<any>,
    fechaModal: Function,
    abreModal: Function,
  },

  setup(props) {

    const { showTimeAlert } = useAlert()
    const emitter = useEmitter()

    const loja: Ref<any> = ref({});
    const arrayLojas: Ref<any[]> = ref([]);
    const lojaLojas: Ref<any> = ref([]);

    const loadSave: Ref<boolean> = ref(false);
    const loadExcluir: Ref<boolean> = ref(false);
    const loadRemoveloja: Ref<boolean> = ref(false);
    const loadResetarSenha: Ref<boolean> = ref(false);
    const dialogVisible: Ref<boolean> = ref(false);

    const clikouResetarSenha: Ref<boolean> = ref(false);
    const novaSenha: Ref<string> = ref('indicar');
    const rawCnpj: Ref<string> = ref('');

    const arrayCargos: Ref<any[]> = ref([]);
    const idLoja: Ref<any> = ref(null);


    function closeModal() {

      props.fechaModal && props.fechaModal()

    }

    function abreModal() {
      loja.value = { ...props.data }
      clikouResetarSenha.value = false
    }

    function enviaLojaValidacaoIpirangaLista(lojaParam) {
      emitter.emit('enviaLojaValidacaoIpirangaLista', { loja: lojaParam })
    }

    function removeLojaValidacaoIpirangaLista(lojaParam) {
      emitter.emit('removeLojaValidacaoIpirangaLista', { loja: lojaParam })
    }

    async function deletarloja() {

      loadExcluir.value = true

      const { data, message } = await Api.delete("deletaLojaValidacaoJetoil", { id_loja: loja.value.id })

      loadExcluir.value = false

      if (!data) {
        dialogVisible.value = false;
        closeModal()
        return showTimeAlert(message, "error")
      }

      removeLojaValidacaoIpirangaLista(data)

      dialogVisible.value = false;
      closeModal()

      return showTimeAlert('Loja deletada com sucesso!', "success")

    }

    async function salvaloja() {

      loadSave.value = true
      //mandando cnpj sem mascara
      loja.value.CNPJ = loja.value.CNPJ.replace(/[^\d]/g, "");

      const { data, message } = await Api.post("createOrUpdateLojaJetoil", { loja: loja.value });

      loadSave.value = false

      if (!data) return showTimeAlert(message, "error");

      //Atualizar lista de lojas
      enviaLojaValidacaoIpirangaLista(data)

      closeModal()

      return showTimeAlert('Loja salva com sucesso!', "success")
    }

    // abrindo modal com loja
    watch(() => props.data, async () => { abreModal() }, { deep: true })

    return {
      loja,
      closeModal,
      deletarloja,
      salvaloja,
      loadSave,
      loadRemoveloja,
      arrayCargos,
      loadExcluir,
      arrayLojas,
      clikouResetarSenha,
      novaSenha,
      loadResetarSenha,
      lojaLojas,
      idLoja,
      dialogVisible,
      rawCnpj
    };
  },
});
